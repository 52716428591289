import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export class AskQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    buttonPress(name) {
        this.setState({ loading: true });
        this.props.answer(name);
    }

    render() {
        return (
            <div>
                <img src={this.props.url} width="500px" onLoad={() => this.setState({ loading: false })} />
                <br/>
                <ButtonGroup className="mb-2">
                    <Button disabled={this.state.loading} variant="primary" onClick={() => this.buttonPress("fish")}>Fish</Button>
                    <Button disabled={this.state.loading} variant="info" onClick={() => this.buttonPress("notfish")}>NotFish</Button>
                    <Button disabled={this.state.loading} variant="secondary" onClick={() => this.buttonPress("notafish")}>Not A Fish</Button>
                    <Button disabled={this.state.loading} variant="danger" onClick={() => this.buttonPress("ping")}>Ping</Button>
                </ButtonGroup>
            </div>
            );
    }
}