import './App.css';
import React, { Component } from 'react';
import { AskQuestion } from './AskQuestion';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = { url: "", answer: "", noCorrect: 0, noIncorrect: 0};
        this.answerSubmitted = this.answerSubmitted.bind(this);
    }

    answers = [
        { url: "guess-images/1.jpg", answer:"fish" },
        { url: "guess-images/2.jpg", answer: "fish" },
        { url: "guess-images/3.jpg", answer: "fish" },
        { url: "guess-images/4.jpg", answer: "fish" },
        { url: "guess-images/5.jpg", answer: "fish" },
        { url: "guess-images/6.jpg", answer: "fish" },
        { url: "guess-images/7.jpg", answer: "fish" },
        { url: "guess-images/8.jpg", answer: "fish" },
        { url: "guess-images/9.jpg", answer: "fish" },
        { url: "guess-images/10.jpg", answer: "fish" },
        { url: "guess-images/11.jpg", answer: "fish" },
        { url: "guess-images/12.jpg", answer: "ping" },
        { url: "guess-images/13.png", answer: "ping" },
        { url: "guess-images/14.png", answer: "ping" },
        { url: "guess-images/15.png", answer: "ping" },
        { url: "guess-images/16.png", answer: "ping" },
        { url: "guess-images/17.png", answer: "notfish" },
        { url: "guess-images/18.jpg", answer: "notfish" },
        { url: "guess-images/19.png", answer: "notfish" },
        { url: "guess-images/20.png", answer: "notafish" },
        { url: "guess-images/21.png", answer: "notafish" },
        { url: "guess-images/22.gif", answer: "notafish" },
        { url: "guess-images/23.png", answer: "fish" },
        { url: "guess-images/24.png", answer: "notfish" },
        { url: "guess-images/25.png", answer: "notfish" }
    ];

    componentDidMount() {
        this.loadAnotherQuestion();
    }

    loadAnotherQuestion() {
        var number = Math.floor(Math.random() * this.answers.length);
        this.setState({ url: this.answers[number].url, answer: this.answers[number].answer });        
    }

    answerSubmitted(answer) {
        if (answer === this.state.answer) {
            this.setState({ noCorrect: this.state.noCorrect + 1 });
        }
        else {
            this.setState({ noIncorrect: this.state.noIncorrect + 1 });
        }
        this.loadAnotherQuestion();
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">                    
                    <AskQuestion url={this.state.url} answer={this.answerSubmitted} />
                    {this.state.noCorrect + this.state.noIncorrect > 0 ?
                        <div>
                            <h1>Accuracy {Math.round((this.state.noCorrect / (this.state.noCorrect + this.state.noIncorrect)) * 100)}%</h1>
                            <p style={{ fontSize: "10pt" }}>Correct: {this.state.noCorrect} / Incorrect: {this.state.noIncorrect}</p>
                        </div>
                        :
                        null
                    }
                </header>
            </div>
        );
    }
}
